export const equips_db = [
    {"label": "Pick-up Ford F-150 #2"},
    {"label": "Pick-up Ford F-150 #3"},
    {"label": "Pick-up Ford F-150 #4"},
    {"label": "Pick-up Ford F-150 #5"},
    {"label": "Pick-up Ford F-150 #6"},
    {"label": "Pick-up Ford F-150 #7"},
    {"label": "Pick-up Ford F-150 #8"},
    {"label": "Pick-up Ford F-150 #9"},
    {"label": "Pick-up GMC Sierra #10"},
    {"label": "Pick-up Nissan Titan #11"},
    {"label": "Pick-up Ford F-150 #12"},
    {"label": "Pick-up Ford - 150 #13"},
    {"label": "Fourgonnette Ford #14"},
    {"label": "Pick-up Ford F-150 #14"},
    {"label": "Pick-up Ford F-150 #15"},
    {"label": "Pick-up Ford F-150 #16"},
    {"label": "Pick-up Ford F-150 #17"},
    {"label": "Pick-up Ford F-350 #18"},
    {"label": "Pick-up Ford F-250 #19"},
    {"label": "Camion Western Star #20"},
    {"label": "Camion Mack #21"},
    {"label": "Camion Mack #22"},
    {"label": "Camion Mack #23"},
    {"label": "Camion Mack #24"},
    {"label": "Camion Inter #25"},
    {"label": "Camion Inter #26"},
    {"label": "Camion Inter #27"},
    {"label": "Camion Inter #28"},
    {"label": "Camion Inter #30"},
    {"label": "Camion Inter #31"},
    {"label": "Camion Inter #32"},
    {"label": "Chargeuse Caterpillar 966H #33"},
    {"label": "Chargeuse Volvo L-150F #34"},
    {"label": "Chargeuse Caterpillar 972H #35"},
    {"label": "Chargeuse Caterpillar 980H #36"},
    {"label": "Chargeuse Caterpillar 950F II #37"},
    {"label": "Chargeur Caterpillar 966 M #38"},
    {"label": "Chargeur Caterpillar 972 M #39"},
    {"label": "Chargeur John Deere 644P #40"},
    {"label": "Chargeuse Caterpillar 938G #41"},
    {"label": "Chargeuse Caterpillar 938G II #42"},
    {"label": "Chargeuse Caterpillar 950H #43"},
    {"label": "Chargeuse John Deere 644K #45"},
    {"label": "Chargeur Volvo L150H #46"},
    {"label": "Chargeuse Caterpillar 908H #47"},
    {"label": "Chargeuse Volvo L-150G #48"},
    {"label": "Chargeuse Caterpillar 950H #49"},
    {"label": "Chargeuse Volvo L-90H #50"},
    {"label": "Semi-remorque Manac #51"},
    {"label": "Semi-remorque Manac #52"},
    {"label": "Chargeuse Caterpillar 972M #53"},
    {"label": "Chargeur sur roues Volvo L150-H #54"},
    {"label": "Semi-remorque Manac #55"},
    {"label": "Chargeuse John Deere 244L #56"},
    {"label": "Chargeur Komatsu WA380 #57"},
    {"label": "Chargeur John Deere 644K #58"},
    {"label": "Semi-remorque Larochelle #59"},
    {"label": "Semi-remorque Manac #60"},
    {"label": "Chargeuse Caterpillar 972 #61"},
    {"label": "Chargeuse Caterpillar 972M #62"},
    {"label": "Semi-remorque Larochelle #63"},
    {"label": "Niveleuse Caterpillar 16015 #64"},
    {"label": "Niveleuse Caterpillar 140H VHP #65"},
    {"label": "Niveleuse Caterpillar 160M #66"},
    {"label": "Remorque Larochelle #67"},
    {"label": "Remorque Larochelle #68"},
    {"label": "Pelle hydraulique Caterpillar 336EL #69"},
    {"label": "Pelle hydraulique Komatsu PC-340 LC-7 #70"},
    {"label": "Pelle hydraulique Caterpillar 349E #71"},
    {"label": "Bouteur Caterpillar D6K2 #72"},
    {"label": "Bouteur John Deere 550K LGP #73"},
    {"label": "Bouteur John Deere 650J LGP #74"},
    {"label": "Bouteur John Deere 550J LGP #75"},
    {"label": "Bouteur Caterpillar D-5K2 LGP #76"},
    {"label": "Pelle hydraulique John Deere 245G-LC #77"},
    {"label": "Pelle hydraulique John Deere 245G-LC #78"},
    {"label": "Rouleau comp. Caterpillar CB-24 #79"},
    {"label": "Rouleau comp. Caterpillar PS-360C #81"},
    {"label": "Rouleau comp. Caterpillar PS-360C #82"},
    {"label": "Rouleau Bomag BW28R #83"},
    {"label": "Rouleau comp. Caterperpillar CB-24B #84"},
    {"label": "Rouleau comp. Caterpillar CB-24B #85"},
    {"label": "Rouleau comp. Caterpillar CB-24 #86"},
    {"label": "Rouleau comp. Dynapac CC-501 #87"},
    {"label": "Rouleau comp. Dynapac CC-722 #88"},
    {"label": "Rouleau comp. Dynapac CP-221 #89"},
    {"label": "Rouleau comp. Caterpillar CS-563D #92"},
    {"label": "Rouleau comp Caterpillar CB-224E #93"},
    {"label": "Rouleau comp. Caterpillar CB-24B #94"},
    {"label": "Rouleau comp. Caterpillar CB-54XW #95"},
    {"label": "Rouleau comp.Caterpillar CB-54XW #96"},
    {"label": "Rouleau comp. Caterpillar CB-54XW #97"},
    {"label": "Rouleau comp. Caterpillar CS-423E #98"},
    {"label": "Rouleau comp. Caterpillar CS-433E #99"},
    {"label": "Camion International HX620 #102"},
    {"label": "Camion épandeur Mack #103"},
    {"label": "Camion International #104"},
    {"label": "Camion International #105"},
    {"label": "Camion International #106"},
    {"label": "Camion International #107"},
    {"label": "Camion épandeur Freight. #108"},
    {"label": "Camion International #109"},
    {"label": "Camion à eau Mack #110"},
    {"label": "Camion à eau Mack #111"},
    {"label": "Camion épandeur Freight. #112"},
    {"label": "Camion à eau International #113"},
    {"label": "Camion à eau Mack #114"},
    {"label": "Citerne à eau Mack #115"},
    {"label": "Camion Freightliner #116"},
    {"label": "Camion à eau Mack #117"},
    {"label": "Camion à eau International #118"},
    {"label": "Camion à eau Mack #119"},
    {"label": "Camion à eau Mack #120"},
    {"label": "Camion à eau Inter #121"},
    {"label": "Camion épandeur Inter. #122"},
    {"label": "Camion à bitume International #123"},
    {"label": "Camion Mack #124"},
    {"label": "Camion Mack #125"},
    {"label": "Camion Mack #126"},
    {"label": "Camion à bitume International #127"},
    {"label": "Camion International #128"},
    {"label": "Camion International #129"},
    {"label": "Camion International #130"},
    {"label": "Camion Inter. #132"},
    {"label": "Camion Inter. #133"},
    {"label": "Camion Freightliner #134"},
    {"label": "Camion Inter. #135"},
    {"label": "Finisseuse Caterpillar AP-1055E #136"},
    {"label": "Finisseuse Caterpillar 1055D #137"},
    {"label": "Finisseuse Caterpillar AP -1055F #139"},
    {"label": "Finisseuse Caterpillar AP-1055F #140"},
    {"label": "Finisseuse Carterpillar AP-1055E #141"},
    {"label": "Finisseuse Caterpillar AP-1055E #142"},
    {"label": "Finisseuse Caterpillar AP-1055F #145"},
    {"label": "Remorque chantier Manac 53' #150"},
    {"label": "Remorque chantier Manac 53’ #151"},
    {"label": "Roulotte Modspace #152"},
    {"label": "Roulotte bureau Mobil #153"},
    {"label": "Roulotte Modspace #154"},
    {"label": "Roulotte Modspace #155"},
    {"label": "Roulotte Modspace #156"},
    {"label": "Roulotte Modspace #157"},
    {"label": "Remorque chantier Manac 53’ #158"},
    {"label": "Remorque Step Deck #159"},
    {"label": "Remorque chantier #160"},
    {"label": "Remorque Témisko #161"},
    {"label": "Fardier J.C. Trailer #162"},
    {"label": "Fardier J.C. Trailer #162"},
    {"label": "Flip essieux #162.1"},
    {"label": "Jeep 3 essieux (Diabolo) #163"},
    {"label": "Roulotte portative GE Capital #164"},
    {"label": "Remorque plate-forme #167"},
    {"label": "Remorque plate-forme #168"},
    {"label": "Fardier 55 t. Manac #169"},
    {"label": "Fardier 55t. Manac #170"},
    {"label": "Pick-up Ford F150 #176"},
    {"label": "Pick-up Ford F150 #177"},
    {"label": "Fourgonnette Ford #178"},
    {"label": "Pick-up Dodge RAM 2500 #179"},
    {"label": "Pick-up Ford F150 #180"},
    {"label": "Pick-up Ford F150 #181"},
    {"label": "Pick-up Ford F150 #182"},
    {"label": "Pick-up Ford F150 #183"},
    {"label": "Fourgonnette Ford #184"},
    {"label": "Pick-up Ford F150 #185"},
    {"label": "Pick-up Ford F150 #186"},
    {"label": "Pick-up Ford F150 #187"},
    {"label": "Fourgonnette Ford #188"},
    {"label": "Pick-up Ford F150 #189"},
    {"label": "Pick-up Ford F150 #190"},
    {"label": "Pick-up Dodge RAM 2500 #191"},
    {"label": "Pick-up Dodge RAM 2500 #192"},
    {"label": "Pick-up Ford F150 #193"},
    {"label": "Pick-up GMC (Sierra 2500) #194"},
    {"label": "Pick-up Ford F150 #195"},
    {"label": "Pick-up Ford Ranger #196"},
    {"label": "Pick-up Ford F150 #197"},
    {"label": "Pick-up Ford F150 #198"},
    {"label": "Pick-up Ford F350 #199"},
    {"label": "Pick-up Ford F150 #200"},
    {"label": "Fourgonnette Chevrolet #201"},
    {"label": "Pick-up Ford F150 #202"},
    {"label": "Pick-up Ford F250 #203"},
    {"label": "Pick-up GMC #204"},
    {"label": "Pick-up Chevrolet Silverado #205"},
    {"label": "Pick-up Ford F150 #206"},
    {"label": "Pick-up GMC Sierra #208"},
    {"label": "Pick-up Ford F150 #209"},
    {"label": "Pick-up Ford F150 #210"},
    {"label": "Bureau mobile (Banc Julien) #210b"},
    {"label": "Pick-up Ford F150 #211"},
    {"label": "Pick-up Ford F150 #212"},
    {"label": "Pick-up Ford F150 #213"},
    {"label": "Pick-up Ford F150 #214"},
    {"label": "Pick-up Ford F150 #215"},
    {"label": "Mini Chargeur Cater. 226B #216"},
    {"label": "Semi-remorque King #225"},
    {"label": "Bureau mobile (balance Neuville) #233"},
    {"label": "Réservoir à bitume Hyway #248"},
    {"label": "Bureau (St-Augustin) #260"},
    {"label": "Mini Chargeur Caterpillar 226B3 #261"},
    {"label": "Balai Mécanique #279"},
    {"label": "Remorque Domestic #288"},
    {"label": "Remorque Croft #297"},
    {"label": "Génératrice 45-50KW-56-63KVA (jour) #298"},
    {"label": "Génératrice 45-50KW-56-63KVA (jour) #299"},
    {"label": "Tour de lumière Atlas #301"},
    {"label": "Tour de lumière Genset #302"},
    {"label": "Compresseur mobile #303"},
    {"label": "Compresseur Gardner Denver #304"},
    {"label": "Compresser Keaser #306"},
    {"label": "Caterpillar H-160DS #308"},
    {"label": "Chariot élévateur ZP500 #312"},
    {"label": "Tamiseur portatif Suntract #328"},
    {"label": "Remorque chantier #329"},
    {"label": "Concasseur El-Jay Classic #330"},
    {"label": "Benne portative(remorque Suntract) #333"},
    {"label": "Concasseur LOKOMO C100B #334"},
    {"label": "Convoyeur Power 4100 90 x 36 #335"},
    {"label": "Planeuse Caterpillar PM822 #343"},
    {"label": "Planeuse Wirtgen W50DC #344"},
    {"label": "Planeuse Wirtgen W210 #345"},
    {"label": "Planeuse Caterpillar PM822 #346"},
    {"label": "Porte-conteneur MAX-ATLAS #347"},
    {"label": "Compresseur Kaeser #348"},
    {"label": "Usine portative Astec # 185 #350"},
    {"label": "Convoyeur – Usine Astec #351"},
    {"label": "Séchoir – Usine Astec #352"},
    {"label": "Dépoussiéreur – Usine Astec #353"},
    {"label": "Silo – Usine Astec #354"},
    {"label": "Réservoir à bitume – Usine Astec #355"},
    {"label": "Benne Portative à recycler #356"},
    {"label": "Citerne à eau Manac #357"},
    {"label": "Remorque Max-Atlas (laboratoire mobile) #360"},
    {"label": "Roulotte portative (balance) #361"},
    {"label": "Remorque plate-forme #363"},
    {"label": "Mini-chargeur Caterpillar 226D #364"},
    {"label": "Remorque Max-Atlas (porte conteneur) #365"},
    {"label": "Remorque génératrice Wabash #367"},
    {"label": "Remorque Max-Atlas #367"},
    {"label": "Gator John Deere XUV #370"},
    {"label": "Panneau message variable PMV #421"},
    {"label": "Panneau message variable PMV #422"},
    {"label": "Flèche #433"},
    {"label": "Flèche #434"},
    {"label": "Flèche #435"},
    {"label": "Flèche #436"},
    {"label": "Flèche #437"},
    {"label": "Mobile de surveillance #438"},
    {"label": "Lumière de signalisation #440"},
    {"label": "Lumière de signalisation #441"},
    {"label": "Lumière de signalisation #442"},
    {"label": "Lumière de signalisation #443"},
    {"label": "Lumière de signalisation #444"},
    {"label": "Lumière de signalisation #445"},
    {"label": "Lumière de signalisation #446"},
    {"label": "Lumière de signalisation #447"},
    {"label": "Mini Chargeur Caterpillar 226B3 #465"},
    {"label": "Finisseuse Weiler W530A #500"},
    {"label": "Remorque plaque chauffante #503"},
    {"label": "Remorque Leeboy 300T (machine à colle) #504"},
    {"label": "Fardier 20 t. M-H #515"},
    {"label": "Fardier 20t. M-H #516"},
    {"label": "Fardier 20t. M-H #517"},
    {"label": "Remorque Asphalt heater #519"},
    {"label": "Remorque Fantastik #520"},
    {"label": "Fardier 20 t. M-H #522"},
    {"label": "Remorque Maxi-roule #523"},
    {"label": "RemorqueGénératrice & signalisation #524"},
    {"label": "Remorque à outils United #525"},
    {"label": "Fardier 12 t. CZ #526"},
    {"label": "Fardier 12 t. Holden/CZ #527"},
    {"label": "Fardier 20 t. M-H #528"},
    {"label": "Fardier 20 t. M-H #529"},
    {"label": "Fardier 20 t. M-H #530"},
    {"label": "Remorque Ideal IDEV7 #531"},
    {"label": "Camion service Ford #551"},
    {"label": "Camion service Chevrolet (signalisation) #552"},
    {"label": "Camion service Ford #553"},
    {"label": "Camion service Ford #554"},
    {"label": "Camion 6 roues Peterbilt #560"},
    {"label": "Camion 6 roues Peterbilt #562"},
    {"label": "Camion 6 roues Peterbilt #563"},
    {"label": "Camion 6 roues Peterbilt #564"},
    {"label": "Camion 6 roues Peterbilt #566"},
    {"label": "Camion 6 roues Peterbilt #567"},
    {"label": "Camion 12 roues #570"},
    {"label": "Camion 12 roues #571"},
    {"label": "Tracteur grader Kubota M5-091 #579"},
    {"label": "Tracteur grader Kubota M5-091 #580"},
    {"label": "Tracteur Kubota #581"},
    {"label": "Pelle Caterpillar 335FL CR #601"},
    {"label": "Pelle Caterpillar 349 FL #602"},
    {"label": "Pelle Caterpillar 349 FL #603"},
    {"label": "Pelle John Deere 75G #604"},
    {"label": "Pelle Caterpillar 325 #605"},
    {"label": "Pelle Caterpillar 325 #606"},
    {"label": "Pelle Caterpillar 349 #607"},
    {"label": "Pelle John Deere 75G #608"},
    {"label": "Pelle John Deere 245G #609"},
    {"label": "Remorque citerne Remtec #625"},
    {"label": "Remorque citerne Remtec #626"},
    {"label": "Remorque citerne Remtec #627"},
    {"label": "Remorque citerne Remtec #628"},
    {"label": "Remorque citerne Remtec #629"},
    {"label": "Fourgonnette Ford #645"},
    {"label": "Fourgonnette Ford #646"},
    {"label": "Fourgonnette Ford #647"},
    {"label": "Fourgonnette Ford #648"},
    {"label": "Unité sanitaire Covid #675"},
    {"label": "Unité sanitaire Covid #676"},
    {"label": "Unité sanitaire Covid #677"},
    {"label": "Unité sanitaire Covid #678"},
    {"label": "Unité sanitaire Covid #679"},
    {"label": "Unité sanitaire Covid #680"},
    {"label": "Camion articulé à benne John Deere 250 #700"},
    {"label": "Camion articulé Volvo A25G #701"},
    {"label": "Mini-chargeur Caterpillar 226D #714"},
    {"label": "Bureau mobile #725"},
    {"label": "Bureau mobile #726"},
    {"label": "Bureau mobile #727"},
    {"label": "Balance et bureau (Banc Angers) #779"},
    {"label": "Shuttle Buggy SB-2500 #800"},
    {"label": "Shuttle Buggy SB-2500D #801"},
    {"label": "Shuttle Buggy Sartigan #295-12 #802"},
    {"label": "Shuttle Buggy Sartigan #296-18 #803"},
    {"label": "Shuttle Buggy SB-2500 #804"},
    {"label": "Rouleau Bomag BW190 #825"},
    {"label": "Rouleau Bomag BW161 #826"},
    {"label": "Rouleau Bomag BW190 #827"},
    {"label": "Rouleau Bomag BW190 #828"},
    {"label": "Rouleau comp. Caterpillar CB-64B #829"},
    {"label": "Rouleau comp. Caterpillar CB13 #831"},
    {"label": "Rouleau comp. Dynapac CC-520 #832"},
    {"label": "Rouleau comp. Dynapac CC-424 #833"},
    {"label": "Rouleau comp. Dynapac CC-520 #834"},
    {"label": "Rouleau comp. Caterpillar CB-46B #835"},
    {"label": "Rouleau Bomag BW161 #836"},
    {"label": "Rouleau Bomag BW191 2022 #837"},
    {"label": "Rouleau Bomag BW120 #840"},
    {"label": "Rouleau Bomag BW120 #841"},
    {"label": "Semi-remorque Cross (convoyeur) #5337"},
    {"label": "Semi-remorque Cross (convoyeur) #5338"},
    {"label": "Semi-remorque Gincor (convoyeur) #5339"},
    {"label": "Semi-remorque Gincor (convoyeur) #5340"},
    {"label": "Semi-remorque Gincor (convoyeur) #5341"},
    {"label": "Semi-remorque Gincor (convoyeur) #5342"},
    {"label": "Semi-remorque Cross Country #5343"},
    {"label": "Semi-remorque North Country #5344"},
    {"label": "Semi-remorque ABS(convoyeur) #5345"},
    {"label": "Semi-remorque ABS(convoyeur) #5346"},
    {"label": "Semi-remorque ABS(convoyeur) #5347"},
    {"label": "Semi-remorque ABS(convoyeur) #5348"},
    {"label": "Semi-remorque ABS(convoyeur) #5349"},
    {"label": "Semi-remorque ABS(convoyeur) #5350"},
    {"label": "Semi-remorque Manac #5353"},
    {"label": "Semi-remorque Manac #5354"},
    {"label": "Camion Tracteur (Sartigan) #175-06"},
    {"label": "Camion Tracteur (Sartigan) #181-07"},
    {"label": "Planeuse (Sartigan) #290-11"},
    {"label": "Rouleau comp. CAT #52277"}
]