import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro,
    GridRowEditStopReasons,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import { frFR } from '@mui/x-data-grid/locales';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


function CustomToolbar(props) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [role, setRole] = React.useState('');
    const [sector, setSector] = React.useState('');
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');
    const [position, setPosition] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const onUserCreation = props.onUserCreation;

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleSectorChange = (event) => {
        setSector(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleNumberChange = (event) => {
        setNumber(event.target.value);
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
  
    const handleOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleSubmit = () => {

        const payload = {
            name: name,
            number: number,
            position: position,
            sector: sector,
            role: role,
            username: username,
            password: password
        }

        try {
            onUserCreation(payload);

            setOpenDialog(false);

            window.location.reload();

        } catch (error) {
            console.log(error)
        }
        
    };
  
    return (
      <>
        <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <GridToolbarFilterButton />
            <Box ml={1}> {/* Adjust the margin as needed */}
                <GridToolbarExport />
            </Box>
            <Box ml={1}> {/* Adjust the margin as needed */}
              <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon/>}
              onClick={handleOpenDialog}
              size='small'
              sx={{ marginLeft: '8px',
                    "& .MuiButton-startIcon": {
                        marginRight: 'auto',
                        marginLeft: 'auto',
                    }
              }}
              />
            </Box>
          </Box>
          <Box>
            <GridToolbarQuickFilter />
          </Box>
        </GridToolbarContainer>
  
        {/* Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Créer un nouvel utilisateur</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Pour inscrire un utilisateur sur Tempo, veuillez entrer les informations ci-dessous.
                </DialogContentText>
                <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    value={name}
                    onChange={handleNameChange}
                    label="Nom complet"
                    type="text"
                    fullWidth
                    sx={{ mb: 1 }} // Adding margin bottom
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                    required
                    margin="dense"
                    id="number"
                    value={number}
                    onChange={handleNumberChange}
                    label="# Employé"
                    type="text"
                    fullWidth
                    sx={{ mb: 1 }} // Adding margin bottom
                    />
                </Grid>
                {/* Repeat the pattern for the other fields */}
                <Grid item xs={12}>
                    <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="position"
                    value={position}
                    onChange={handlePositionChange}
                    label="Poste/Métier"
                    type="text"
                    fullWidth
                    sx={{ mb: 1 }} // Adding margin bottom
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Secteur</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sector}
                            label="Secteur"
                            onChange={handleSectorChange}
                            >
                            <MenuItem value='Bureau'>Bureau</MenuItem>
                            <MenuItem value='Carrières/Sablières'>Carrières/Sablières</MenuItem>
                            <MenuItem value='Hors construction'>Hors construction</MenuItem>
                            <MenuItem value='Laboratoire'>Laboratoire</MenuItem>
                            <MenuItem value='Signalisation-04'>Signalisation-04</MenuItem>
                            <MenuItem value='Signalisation-06'>Signalisation-06</MenuItem>
                            <MenuItem value='Tech-Chantier'>Tech-Chantier</MenuItem>
                            <MenuItem value='Tech-Chantier'>Sartigan - Bureau</MenuItem>
                            <MenuItem value='Tech-Chantier'>Sartigan - Usine</MenuItem>
                            </Select>
                        </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Rôle</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Rôle"
                        onChange={handleRoleChange}
                        >
                        <MenuItem value='admin'>Administrateur</MenuItem>
                        <MenuItem value='foreman'>Contremaître</MenuItem>
                        <MenuItem value='employee'>Employé</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                <TextField
                    required
                    margin="dense"
                    id="username"
                    value={username}
                    onChange={handleUsernameChange}
                    label="Nom d'utilisateur"
                    type="text"
                    fullWidth
                    sx={{ mb: 1 }} // Adding margin bottom
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    required
                    margin="dense"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    label="Mot de passe"
                    type="text"
                    fullWidth
                    sx={{ mb: 1 }} // Adding margin bottom
                    />
                </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Ajouter
                </Button>
            </DialogActions>
            </Dialog>
        
      </>
    );
  }

const roleMapping = {
    'Employé': 'employee',
    'Contremaître': 'foreman',
    'Administrateur': 'admin'
};

const reverseRoleMapping = {
    'employee': 'Employé',
    'foreman': 'Contremaître',
    'admin': 'Administrateur'
};

function addIdToDicts(listOfDicts) {
    let idCounter = 1;
    for (const dict of listOfDicts) {
      dict.id = idCounter++;
      dict.temp_role = reverseRoleMapping[dict.role]
    }
  }

export default function UserFilteringGrid(props) {

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      };

    const onActiveChange = props.onActiveChange;
    const onUserUpdate = props.onUserUpdate;
    const onUserCreation = props.onUserCreation;
    const onUserDeletion = props.onUserDeletion;

    const users = props.users;
    addIdToDicts(users);

    const [rows, setRows] = React.useState([]);


    React.useEffect(() => {
        const initialRows = users.map(user => ({ ...user }));
        setRows(initialRows);
    }, [users]);

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const xl = useMediaQuery(theme.breakpoints.up("xl"));

    const defaultUserRenderCell = (params) => (
        <Box>
          {params.row.name}
        </Box>
      );

    const xsUserRenderCell = (params) => (
        <Box>
            <Box>{params.row.name}</Box>
            <Box sx={{ fontSize: 12 }}>{params.row.number}</Box>
        </Box>
    );
    
    const userRenderCell = lg ? defaultUserRenderCell : xsUserRenderCell;

    let columnVisibility;
    let leftPadding = 0;
    let rightPadding = 0;
    let raidusBorder = 0;

    if (xl) {

        columnVisibility = {
            action: false
        };

        leftPadding = 25
        rightPadding = 25
        raidusBorder = 0
    
      } else if (lg) {

        columnVisibility = {
            created_at: false,
            action: false
        };

      } else if (md) {

        columnVisibility = {
            created_at: false,
            number: false,
            position: false,
            is_active: false,
            action: false 
        };

      } else if (sm) {

        columnVisibility = {
            created_at: false,
            number: false,
            position: false,
            is_active: false,
            action: false   
        };

      } else if (xs) {

        columnVisibility = {
            created_at: false,
            number: false,
            position: false,
            sector: false,
            is_active: false,
            action: false
        };

      }

    let columns = [
        {
            field: 'name',
            headerName: 'Nom',
            renderHeader: () => (
                <strong>
                  {'Nom'}
                </strong>
            ),
            type: 'string',
            flex: 0.8,
            renderCell: userRenderCell
        },
        {
            field: 'number',
            headerName: "Numéro d'employé",
            renderHeader: () => (
                <strong>
                  {"# Employé"}
                </strong>
            ),
            type: 'String',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                if (params.row.number === null) {
                    return (
                        <Box>
                            <Box>--</Box>
                        </Box>
                    )
                } else {
                    return (
                        <Chip
                        label={params.row.number}
                        size="small"
                        style={{ marginTop: '5px' }}
                        />
                    )
                }
            }
        },
        
        {
            field: 'position',
            headerName: "Poste",
            renderHeader: () => (
                <strong>
                  {'Poste'}
                </strong>
            ),
            type: 'String',
            editable: true,
            flex: 1
        },
        {
            field: 'sector',
            headerName: "Secteur",
            renderHeader: () => (
                <strong>
                  {'Secteur'}
                </strong>
            ),
            type: 'singleSelect',
            editable: true,
            valueOptions: [
                'Bureau',
                'Carrières/Sablières',
                'Hors construction',
                'Laboratoire',
                'Signalisation-02',
                'Signalisation-04',
                'Tech-Chantier',
                'Sartigan - Bureau',
                'Sartigan - Usine'
            ],
            flex: 1,
        },
        {
            field: 'temp_role',
            headerName: "Role",
            renderHeader: () => (
                <strong>
                  {'Rôle'}
                </strong>
            ),
            type: 'singleSelect',
            editable: true,
            valueOptions: [
                'Employé',
                'Contremaître',
                'Administrateur'
            ],
            flex: 1
        },
        {
            field: 'is_active',
            headerName: "Actif",
            renderHeader: () => (
                <strong>
                  {'Actif'}
                </strong>
            ),
            type: 'boolean',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: (params) => {

                const handleSwitchChange = (event) => {
                    const newValue = event.target.checked;
                    let temp_row = params.row;
                    temp_row.is_active = newValue;

                    const user_id = params.row.user_id;

                    setRows(rows.map((row) => (row.id === temp_row.id ? temp_row : row)))
                    
                    const payload = {
                        is_active: newValue
                    };

                    onActiveChange(user_id, payload);
                };

                return (
                    <Box>
                        <Switch
                            checked={params.row.is_active}
                            onChange={handleSwitchChange}
                        />
                    </Box>
                )
            }
        },
        {
            field: 'created_at',
            headerName: "Créé le",
            renderHeader: () => (
                <strong>
                  {'Créé le'}
                </strong>
            ),
            type: 'String',
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Supprimer',
            type: 'actions',
            flex: 1,
            renderCell: (params) => {
              const handleClick = () => {
                const user_id = params.row.user_id;
                console.log('DELETE', user_id);
                // Assuming you have an array of dictionaries called 'arrayOfDicts' and a user_id called 'userIdToRemove'
                const updatedRows = rows.filter(row => row.user_id !== user_id);
                setRows(updatedRows);

                onUserDeletion(user_id);
                
              };
        
              return (
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={handleClick}
                  >
                    <DeleteIcon style={{ color: '#343434' }}/>
                </IconButton>
              );
            }
          }
    ]

  return (
    <Box sx={{ backgroundColor: '#f0f0f0', paddingTop: 0, paddingBottom: 5, paddingLeft: leftPadding, paddingRight: rightPadding }}>
        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: raidusBorder }}>
            {rows.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <DataGridPro
                editMode="row"
                pagination
                paginationPageSize={100}
                disableColumnSelector
                disableDensitySelector
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                columnVisibilityModel={columnVisibility}
                rows={rows}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    onUserCreation: onUserCreation
                },
                }}
                processRowUpdate={(updatedRow, originalRow) => {
                    const user_id = updatedRow.user_id;

                    setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)))

                    const payload = {
                        "is_active": updatedRow.is_active,
                        "position": updatedRow.position,
                        "role": roleMapping[updatedRow.temp_role],
                        "sector": updatedRow.sector
                    };

                    onUserUpdate(user_id, payload);

                    return updatedRow;
                }
                }
                onProcessRowUpdateError={(error) => {console.log(error)}}
            />
            )}
        </Box>
    </Box>
  );
}