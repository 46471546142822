import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Ensure you import jspdf-autotable
import dayjs from 'dayjs';
import roundToQuarter from './scale';
import { fetchReportSummary } from '../services/fetch';

import logo from '../assets/cppi-logo-black2.png'; // Update the path to your logo file
import { da } from 'date-fns/locale';


const handleTotal = (start, end, mealTime) => {
  const newStart = dayjs(start);
  const newEnd = dayjs(end);
  const tempNewTotal = newEnd.diff(newStart, 'hour', true);
  if (isNaN(tempNewTotal)) {
    return 0;
  } else {
    if (tempNewTotal < 0) {
      const newTotal = 24 + tempNewTotal - Number(mealTime);
      return roundToQuarter(newTotal);
    } else {
      const newTotal = tempNewTotal - Number(mealTime);
      return roundToQuarter(newTotal);
    }
  }
}

const handleBigTotal = (activities) => {
  let total = 0;
  if (activities) {
    activities.forEach((activity) => {
      total += handleTotal(activity.start, activity.end, activity.meal_time); 
    });
  }
  return total.toFixed(2);
}


function groupAndSumArrays(arrayOfArrays) {
  // Use an object to store the accumulated values for each name
  const groupedByName = arrayOfArrays.reduce((acc, arr) => {
    const [name, a, b, c, d] = arr;
    // If the name is already in the accumulator, add up the values; otherwise, initialize it
    if (!acc[name]) {
      acc[name] = [name, 0, 0, 0, 0];
    }
    acc[name][1] += a;
    acc[name][2] += roundToQuarter(b);
    acc[name][3] += roundToQuarter(c);
    acc[name][4] += roundToQuarter(d);
    return acc;
  }, {});

  // Convert the grouped object into an array of arrays
  const resultArray = Object.values(groupedByName);

  return resultArray;
}


function updateTimes(my_array) {

  for (let i = 0; i < my_array.length; i++) {
    if (my_array[i].length >= 3) {
        my_array[i][4] = roundToQuarter(my_array[i][4]);
        my_array[i][5] = roundToQuarter(my_array[i][5]);
        my_array[i][6] = roundToQuarter(my_array[i][6]);
    }
  }
}

function updateEquips(my_array) {

  for (let i = 0; i < my_array.length; i++) {
    if (my_array[i].length >= 3) {
        my_array[i][2] = roundToQuarter(my_array[i][2]);
    }
  }
}


function checkNull(variable) {
  if (variable === null) {
      return '';
  } else {
      return variable;
  }
}


const jsPdfGenerator = (summary) => {

  const doc = new jsPDF('p', 'pt');

  // Add a logo to the leftmost part of the PDF
  doc.addImage(logo, 'PNG', 340, 30);

  // Set font styles
  doc.setFont('times', 'normal');
  doc.setFontSize(16);

  // Calculate the height of the logo
  const logoHeight = 70; // Update with the actual height of your logo

  // Add a header
  doc.setTextColor(0, 0, 0); // Set text color to orange
  doc.setFont(undefined, 'bold'); // Set font style to bold
  doc.setFontSize(18);
  doc.text('Feuille de temps', 180, 100, { align: 'right' });

  const titleLineWidth = 530; // Adjust the line width based on the title length
  const titleLineY = 110; // Adjust the line position below the title

  const secondLineY = titleLineY + 75; //   Adjust the vertical position of the second line

  doc.setFont(undefined, 'normal');; // Set font style to bold
  doc.setFontSize(11);

  const verticalLineX = secondLineY + 100; // Adjust the x-coordinate of the vertical line
  const verticalLineHeight = 110; // Adjust the height of the vertical line

  // doc.line(55, titleLineY, titleLineWidth, titleLineY);
  doc.line(55, 110, 530, 110);
  doc.line(55, 185, 530, 185);
  doc.line(55, 215, 530, 215);
  doc.line(55, 245, 530, 245);
  doc.line(55, 275, 530, 275);
  
  doc.line(285, 245, 285, 145);
  // doc.line(55, secondLineY + 35, titleLineWidth, secondLineY + 35);
  // doc.line(55, secondLineY + 65, titleLineWidth, secondLineY + 65);
  // doc.line(55, secondLineY + 95, titleLineWidth, secondLineY + 95);

  doc.setFontSize(11);
  doc.setFont(undefined, 'bold');; // Set font style to bold

  doc.text("Nom de l'employé : ", 150, 175, { align: 'right'  });
  doc.text("Approuvé par : ", 380, 175, { align: 'right' });
  doc.text("Numéro de l'employé : ", 165, 205, { align: 'right' });
  doc.text("Période finissante le : ", 408, 205, { align: 'right' });
  doc.text("Heures à temps simple : ", 171, 235, { align: 'right' });
  doc.text("Heures à temps et demi : ", 424, 235, { align: 'right' });
  doc.text("Kilomètres : ", 118, 265, { align: 'right' });


  doc.setFont(undefined, 'normal'); // Set font style to bold
   
  doc.text(`${summary.employee_name}`, 160, 175, { align: 'left' });
  doc.text(`${checkNull(summary.approved_by)}`, 390, 175, { align: 'left' });
  doc.text(`${summary.employee_number}`, 175, 205, { align: 'left' });
  doc.text(`${summary.end}`, 415, 205, { align: 'left' });
  doc.text(`${roundToQuarter(summary.simple_time)}` + " heures", 180, 235, { align: 'left' });
  doc.text(`${roundToQuarter(summary.time_and_a_half)}` + " heures", 435, 235, { align: 'left' });
  doc.text(`${roundToQuarter(summary.distance)}` + " km", 128, 265, { align: 'left' });

  const data = summary.days

  updateTimes(data);
  

  // Add a subheader
  // doc.setFontSize(12);
  // doc.text('Semaine du 7 Janvier, 2024 - 13 Janvier, 2024', 290, 50 + logoHeight, { align: 'right' });

  // Add a table with sample data
  const columns = ['Date', 'Début', 'Fin', 'Repas', 'Temps rég.', 'Temps demi.', "Total", "Commentaire"];

  // Adjust the startY to position the table below the logo and text
  const tableY = 250 + logoHeight;

  const columnStyles = {
    0: { cellWidth: 60 },
    1: { cellWidth: 45 },
    2: { cellWidth: 45 },
    3: { cellWidth: 45 },
    4: { cellWidth: 71 },
    5: { cellWidth: 71 },
    6: { cellWidth: 71 },
  };

  const data3 = summary.equips;
  const columns3 = ['Équipement', 'Contrat', 'Temps'];

  // Check if 'autoTable' is available in 'doc'
  if (typeof doc.autoTable === 'function') {
    doc.autoTable({
      head: [
        [{ content: 'Jours de travail', colSpan: columns.length, styles: { halign: 'center', fillColor: [242, 133, 33] } }],
        columns
      ],
      body: data,
      startY: tableY,
      theme: 'grid',
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0], // Border color (black)
        textColor: [0, 0, 0]
      },
      headStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0], // Border color (black)
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0]
      },
      columnStyles: columnStyles
    });

    const secondTableY = tableY + doc.autoTable.previous.finalY - 295;

    const columns2 = ['Contrat', 'KM', 'Temps rég.', 'Temps demi.', "Total"];
    const contracts = summary.contracts;

    const data2 = groupAndSumArrays(contracts);

    const columnStyles2 = {
      1: { cellWidth: 45},
      2: { cellWidth: 71 },
      3: { cellWidth: 71 },
      4: { cellWidth: 71 }
    };

  

    doc.autoTable({
      head: [
        [{ content: 'Contrats', colSpan: columns2.length, styles: { halign: 'center', fillColor: [242, 133, 33] } }],
        columns2
      ], // Replace with your second table headers
      body: data2,
      startY: secondTableY,
      theme: 'grid',
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0], // Border color (black)
        textColor: [0, 0, 0]
      },
      headStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0], // Border color (black)
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0]
      },
      columnStyles: columnStyles2
    });

    const thirdTableY = secondTableY + doc.autoTable.previous.finalY - 295;

    const columns3 = ['Équipement', 'Contrat', 'Temps'];

    const data3 = summary.equips;

    updateEquips(data3);

    const columnStyles3 = {
      0: { cellWidth: 222 },
      1: { cellWidth: 221 },
      2: { cellWidth: 71 }
    }  

    doc.autoTable({
      head: [
        [{ content: 'Équipements', colSpan: columns3.length, styles: { halign: 'center', fillColor: [242, 133, 33] } }],
        columns3
      ], // Replace with your second table headers
      body: data3,
      startY: thirdTableY,
      theme: 'grid',
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0], // Border color (black)
        textColor: [0, 0, 0]
      },
      headStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0], // Border color (black)
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0]
      },
      columnStyles: columnStyles3
    });

  } else {
    console.error("autoTable function is not available in the 'doc'.");
  }


  // Add a footer
  doc.setFontSize(10);
  doc.text('Généré le: ' + new Date().toLocaleString(), 40, doc.internal.pageSize.height - 80);

  doc.setFont(undefined, 'bold');; // Set font style to bold
  doc.text(`***Dépenses (${summary.expenses})`, 490, doc.internal.pageSize.height - 80);

  // Save the PDF
  doc.save('timesheet_report.pdf');

};


const jsPdfGenerator2 = () => {
  const doc = new jsPDF();

  // Sample data for the table
  const tableData = [
    { section: 'Lundi', column2: '123 004', column3: '6.50', column4: '0.00', column5: '90' },
    { section: 'Lundi', column2: '123 008', column3: '2.00', column4: '0.00', column5: '13' },
    { section: 'Mardi', column2: '823 012', column3: '5.00', column4: '0.00', column5: '28' },
    { section: 'Mardi', column2: '823 014', column3: '3.50', column4: '0.00', column5: '0.00' },
    { section: 'Mercredi', column2: '823 012', column3: '5.00', column4: '0.00', column5: '28' },
    { section: 'Mercredi', column2: '823 014', column3: '3.50', column4: '0.00', column5: '0.00' },
  ];

  // Function to group rows based on a specific column
  const groupRows = (data, column) => {
    const groupedData = {};
    data.forEach(row => {
      const key = row[column];
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(row);
    });
    return groupedData;
  };

  // Group data by the 'section' column
  const groupedData = groupRows(tableData, 'section');

  // Define table columns
  const columns = ['Contrat', 'Temps régulier', 'Temps et demi', 'Distance (KM)'];

  // Function to draw the grouped table
  const drawGroupedTable = (groupedData, startY) => {
    let currentY = startY;
    let isFirstSection = true;
  
    Object.keys(groupedData).forEach(section => {
      // Draw the table for the current section, including column headers
      doc.autoTable({
        head: isFirstSection ? [columns] : [],
        body: [
          [{ content: section, colSpan: 4, styles: { halign: 'left', fillColor: [140, 220, 255], fontStyle: 'bold' } }],
          ...groupedData[section].map(row => Object.values(row).slice(1)),
        ],
        startY: currentY, // Start at the current Y position
        theme: 'plain',
        bodyStyles: {
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
        },
        headStyles: {
          fillColor: [200, 220, 255],
          textColor: [0, 0, 0],
        },
      });
  
      // Update the Y coordinate for the next section
      currentY = doc.autoTable.previous.finalY + 5; // Stick the title row with the table
      isFirstSection = false;
    });
  };

  // Draw the grouped table
  drawGroupedTable(groupedData, 10);

};


const fetchDataAndGeneratePdf = async (report_id) => {
  try {
    const summary = await fetchReportSummary(report_id);
    jsPdfGenerator(summary);
  } catch (error) {
    console.error('Error in fetching data or generating PDF:', error);
    // Handle the error
  }
};

export default fetchDataAndGeneratePdf;
