import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';


function ModalList({error_log, redirect}) {

    const handleClick = (idx) => {
        redirect(idx);
    }

  return (
    <div>
        <List dense>
            {error_log.map((err, index) => (
                <ListItem key={index} sx={{ padding: '8px', display: 'flex', alignItems: 'center' }}>
                {/* Set width to 100% to take full width */}
                <Alert severity={err.severity} variant="standard" sx={{ width: '100%', fontSize: '0.875rem' }}>
                  Attention - {err.error} pour la journée de <Link onClick={() => handleClick(err.tab)}>{err.day}</Link>
                </Alert>
              </ListItem>
        ))}   
        </List>
    </div>
  );
}

export default ModalList;