import jwt_decode from 'jwt-decode'



export function isConnected() {

    const token = localStorage.getItem('token');

      if (!token) {
        return false;
      }

      return true;

}

export const isTokenExpired = (token) => {
  const decodedToken = jwt_decode(token);
  const currentTime = Date.now() / 1000; // Convert to seconds
  return (decodedToken.exp - 10) < currentTime;
}