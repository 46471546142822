import PrimaryAppBar from "../components/layout/appbar";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import MobileUserList from "../components/user_list/mobile/user_list";
import { fetchUsers, UpdateUserActive, UpdateUser, CreateUser, DeleteUser } from "../services/fetch";

export function UserListPage() {

    const isMobile = useMediaQuery({maxWidth: 767});

    const [users, setUsers] = useState([]);

    useEffect(() => {
      async function fetchUsersData() {
        try {
          const response = await fetchUsers();
          setUsers(response);
        } catch (error) {
          console.log(error);
        }
      }
      fetchUsersData();
    }, []);


    const handleUserActiveUpdate = (user_id, data) => {
      const response = UpdateUserActive(user_id, data)
      .then((response) => {
        console.log('success');
      })
      .catch((error) => {
        console.log(error);
      })
    };

    const handleUserUpdate = (user_id, data) => {
      const response = UpdateUser(user_id, data)
      .then((response) => {
        console.log('success');
      })
      .catch((error) => {
        console.log(error);
      })
    };

    const handleUserCreation = (data) => {
      const response = CreateUser(data)
      .then((response) => {
        console.log('success');
      })
      .catch((error) => {
        console.log(error);
      })
    };

    const handleUserDeletion = (user_id) => {
      const response = DeleteUser(user_id)
      .then((response) => {
        console.log('success');
      })
      .catch((error) => {
        console.log(error);
      })
    };

    return (
      <div>
          <PrimaryAppBar page='Utilisateurs'></PrimaryAppBar>
          {isMobile ? <MobileUserList users={users} onUserActiveChange={handleUserActiveUpdate} onUserUpdate={handleUserUpdate} onUserCreation={handleUserCreation} onUserDeletion={handleUserDeletion}></MobileUserList> : <MobileUserList users={users} onUserActiveChange={handleUserActiveUpdate} onUserUpdate={handleUserUpdate} onUserCreation={handleUserCreation} onUserDeletion={handleUserDeletion}></MobileUserList>}
      </div>
    );
  }
  
