import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ActivityMobile from './activity';
import NoteMobile from './note';
import Box from '@mui/material/Box';
import ContractListGrid from './contracts';
import InfoMobile from './info';
import SaveIcon from '@mui/icons-material/Save';
import { createTheme, responsiveFontSizes } from '@mui/material';
import { ThemeContext, ThemeProvider } from '@emotion/react';
import LoadingMobile from './loading';
import DropzoneMobile from './dropzone';
import SuccessToast from './success';
import PrimaryAppBar from '../../layout/appbar';
import MyModal from './modal';
import { useNavigate, useLocation } from 'react-router-dom';
import EquipListGrid from './equip';


let theme = createTheme();
theme = responsiveFontSizes(theme)



function MobileTimesheet(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const [selectedDate, setSelectedDate] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const initAccessValue = (status, approved) => {
    if (status === 'En cours') {
      if (approved === true) {
        return false
      } else {
        return true;
      }
    } else if (status === 'En traitement') {
      return false;
    } else if (status === 'Complété') {
      return false;
    } else if (status === 'Archivé') {
      return false;
    } else {
      return false;
    }
  }

  const onStatusChange = props.onStatusChange;
  const report = props.report;
  const onDataChange = props.onDataChange;
  const onDataSave = props.onDataSave;
  const isUpdate = props.isUpdate;

  
  

  const load = props.load;
  const isSuccessful = props.isSuccessful;
  const totalWorkTime = props.totalWorkTime;
  const status = report.status;
  const approved = report.approved;
  console.log(status);


  // console.log(initAccessValue(status, approved));

  const [access, setAccess] = React.useState(initAccessValue(status, approved));
  const [page, setPage] = React.useState("timesheet")

  React.useEffect(() => {
    if (status === 'En cours') {
      setAccess(true);
    } else if (approved === true) {
      console.log('HERE')
      setAccess(false)
    } else if (status === 'En traitement') {
      setAccess(false);
    } else if (status === 'Complété') {
      setAccess(false);   
    } else if (status === 'Archivé') {
      setAccess(false);
    } else {
      setAccess(false);
    }
  }, [status, approved])


  const handleDateChange = (event, newValue) => {
    // navigate(`${location.pathname}?tab=${newValue}`)
    setSelectedDate(newValue);
    window.scrollTo(0, 0);
  };

  const handleSetDateChange = (val) => {
    // navigate(`${location.pathname}?tab=${val}`)
    setSelectedDate(val);
    window.scrollTo(0, 0);
  }
  

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  return (
    <div style={{ backgroundColor: '#f0f0f0' }}>
      <ThemeProvider theme={theme}>
        <MyModal isOpen={isModalOpen} onClose={closeModal} onSave={onDataSave} onRedirect={handleSetDateChange} report={report}></MyModal>
        <div style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000, // Adjust the z-index as needed
          width: '100%',
        }}>
          {isSuccessful && <SuccessToast />}
        </div>
        <PrimaryAppBar page='Rapport de temps' report_id={report.report_id}></PrimaryAppBar>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper', position: 'sticky', top: 0, zIndex: 5 }}>
          {load && <LoadingMobile />}
          <InfoMobile report={report} onDataChange={onDataChange} onDataSave={onDataSave} onStatusChange={onStatusChange} isUpdate={isUpdate} /> 
          <Tabs
            value={selectedDate}
            onChange={handleDateChange}
            centered={false}
            scrollButtons="auto"
            variant="scrollable"
          >
            {daysOfWeek.map((day, index) => (
              <Tab key={index} label={day} />
            ))}
            <Tab key='depenses' label={`dépenses (${report.expenses.length})`} />
            <Tab key='equip' label='Équipements' />
          </Tabs>
        </Box>
        <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', padding: '16px', maxWidth: '1400px', margin: '0 auto' }}>
          {daysOfWeek.map((day, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} key={index}>
                {selectedDate === index && (
                  <Box>
                    <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px' }}>
                      <ActivityMobile report={report} idx={index} onDataChange={onDataChange} />
                    </Box>
                    <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px', marginTop: '12px' }}>
                      <ContractListGrid report={report} idx={index} onDataChange={onDataChange} />
                    </Box>
                    <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px', marginTop: '12px' }}>
                      <NoteMobile report={report} idx={index} onDataChange={onDataChange} />
                    </Box>
                    <Box sx={{ paddingTop: '12px', borderRadius: '6px' }}>
                      <Button variant="contained" startIcon={<SaveIcon />} onClick={openModal} disabled={!access} fullWidth>Enregistrer</Button>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          ))}
          {selectedDate === 7 && (
            <Grid container spacing={2} key='depenses'>
              <Grid item xs={12} key='depenses'>
                <Box>
                  <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px' }}>
                    <DropzoneMobile report={report} onDataChange={onDataChange} />
                  </Box>
                  <Box sx={{ paddingTop: '12px', borderRadius: '6px' }}>
                    <Button variant="contained" startIcon={<SaveIcon />} onClick={onDataSave} disabled={!access} fullWidth>Enregistrer</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {selectedDate === 8 && (
            <Grid container spacing={2} key='equip'>
              <Grid item xs={12} key='equip'>
                <Box>
                  <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px' }}>
                    <EquipListGrid report={report} idx={1} onDataChange={onDataChange}></EquipListGrid>
                  </Box>
                  <Box sx={{ paddingTop: '12px', borderRadius: '6px' }}>
                    <Button variant="contained" startIcon={<SaveIcon />} onClick={openModal} disabled={!access} fullWidth>Enregistrer</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}


export default MobileTimesheet;
