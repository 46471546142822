import MobileTimesheetList from "../components/timesheet_list/mobile/timesheet_list";
import PrimaryAppBar from "../components/layout/appbar";
import { useMediaQuery } from "react-responsive";
import { fetchReports } from "../services/fetch";
import { useEffect, useState } from "react";

export function TimesheetsPage() {

    const isMobile = useMediaQuery({maxWidth: 767});

    const [reports, setReports] = useState([]);


    useEffect(() => {
      async function fetchReportsData() {
        try {
          const response = await fetchReports();
          setReports(response);
        } catch (error) {
          console.log(error);
        }
      }
      fetchReportsData();
    }, []);


    return (
      <div>
          <PrimaryAppBar page='Rapports de temps'></PrimaryAppBar>
          {isMobile ? <MobileTimesheetList reports={reports}></MobileTimesheetList> : <MobileTimesheetList reports={reports}></MobileTimesheetList>}
      </div>
    );
  }
  