import PrimaryAppBar from "../components/layout/appbar";
import ImportTable from "../components/import/import_table";

export function ImportPage() {


    return (
      <div>
          <PrimaryAppBar></PrimaryAppBar>
          <ImportTable></ImportTable>
      </div>
    );
  }