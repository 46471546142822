import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import roundToQuarter from '../../../utils/scale';


let theme = createTheme();
theme = responsiveFontSizes(theme);


const StyledBadge = styled(Badge)(({ theme, badge }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: badge || '#44b700',
      color: badge || '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: -1,
        left: -1,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));



export default function TimesheetList(props) {
  const [open, setOpen] = React.useState(false);

  const reports = props.reports;

  const navigate = useNavigate();

  const handleClick = (report_id) => {
    navigate(`/timesheets/${report_id}`);
  };

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: 'UTC'
  };

  const handleTotal = (start, end, mealTime) => {
    const newStart = dayjs(start);
    const newEnd = dayjs(end);
    const tempNewTotal = newEnd.diff(newStart, 'hour', true);
    if (isNaN(tempNewTotal)) {
      return 0;
    } else {
      if (tempNewTotal < 0) {
        const newTotal = 24 + tempNewTotal - Number(mealTime);
        return roundToQuarter(newTotal);
      } else {
        const newTotal = tempNewTotal - Number(mealTime);
        return roundToQuarter(newTotal);
      }
    }
  }

  const handleBigTotal = (activities) => {
    let total = 0;
    if (activities) {
      activities.forEach((activity) => {
        total += handleTotal(activity.start, activity.end, activity.meal_time); 
      });
    }
    return total.toFixed(2);
  }

  const badgeColor = (status) => {
    const badgeColor = status === 'En cours' ? '#44b700' : status === 'En traitement' ? '#FFEA00' : status === 'Complété' ? '#0096FF' : status === 'Archivé' ? '#EE4B2B'  : '#44b700';
    return badgeColor;
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {reports.map((report) => (
        <Box key={report.report_id}>
          <ListItemButton onClick={() => handleClick(report.report_id)}>
            <ListItemIcon>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            badge={badgeColor(report.status)}
                        >
                            <Avatar>
                                <AccessTimeIcon></AccessTimeIcon>
                            </Avatar>
                        </StyledBadge>
              </ListItemIcon>
          <ListItemText primary={`Semaine du ${new Date(report.start).toLocaleDateString("fr-FR", options)}`} secondary={report.user_name}/>
          {/* {open ? <ExpandLess onClick={handleCollapse} /> : <ExpandMore onClick={handleCollapse} />} */}
          <Typography variant="caption">
            <b>{handleBigTotal(report.activities)}h</b>
          </Typography>
          
        </ListItemButton>
        <Divider />
        </Box>
      ))}
    </List>
  );
}
