import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import roundToQuarter from '../../../utils/scale';
import dayjs from 'dayjs';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ro } from 'date-fns/locale';


let theme = createTheme();
theme = responsiveFontSizes(theme);    

const handleTotal = (start, end, mealTime) => {
  const newStart = dayjs(start);
  const newEnd = dayjs(end);
  const tempNewTotal = newEnd.diff(newStart, 'hour', true);
  if (isNaN(tempNewTotal)) {
    return 0;
  } else {
    if (tempNewTotal < 0) {
      const newTotal = 24 + tempNewTotal - Number(mealTime);
      return roundToQuarter(newTotal);
    } else {
      const newTotal = tempNewTotal - Number(mealTime);
      return roundToQuarter(newTotal);
    }
  }
}

const handleBigTotal = (activities) => {
  let total = 0;
  if (activities) {
    activities.forEach((activity) => {
      total += handleTotal(activity.start, activity.end, activity.meal_time); 
    });
  }
  return total.toFixed(2);
}

function SimplePaper(props) {

  const report = props.report;
  const isUpdate = props.isUpdate;
  const onDataChange = props.onDataChange;
  const onStatusChange = props.onStatusChange;
  const role = props.role;

  const [approved, setApproved] = React.useState(report.approved);
  const [checkAccess, setCheckAccess] = React.useState(role === 'admin' || role === 'foreman');

  const handleCheckboxChange = (event) => {
    const newData = report;
    newData.approved = event.target.checked;
    onDataChange(newData);
    setApproved(event.target.checked);
    const payload = {
      approved: !approved
    };
    onStatusChange(payload);
  };

  const bigTotal = handleBigTotal(report.activities);

  let reg = bigTotal;
  let half = 0;

  if (bigTotal > 40) {
    reg = 40;
    half = bigTotal - 40;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          height: '125px', // Set a fixed height
          width: '50%',    // Set a percentage-based width
          minWidth: '160px', // Set a minimum width if needed
          maxWidth: '500px', // Set a maximum width if needed
        },
      }}
    >
      <Paper elevation={24} sx={{ backgroundColor: '#595959' }}>
        <Box sx={{ padding: 1, color: 'white' }}>
          <Box>
            <Typography fontSize={13} theme={theme}>
              Total d'heures : <b>{bigTotal}</b>
            </Typography>
          </Box>
          <Box sx={{ marginTop: '5px' }}>
            <Typography fontSize={13} theme={theme}>
              Régulières : <b>{reg}</b>
            </Typography>
          </Box>
          <Box sx={{ marginTop: '5px' }}>
            <Typography fontSize={13} theme={theme}>
              Temps & demi : <b>{half.toFixed(2)}</b>
            </Typography>
          </Box>
          <Box>
            <FormGroup>
              {/* <FormControlLabel control={<Checkbox color="success" checked={approved} onChange={handleCheckboxChange} />} label="Approuvé" /> */}
              <FormControlLabel control={<Checkbox color="success" checked={approved} onChange={handleCheckboxChange} disabled={!checkAccess}/>} label={<Typography fontSize={13} theme={theme}><b>Approuvé</b></Typography>} />
            </FormGroup>
          </Box>
          
          {/* {isUpdate ? (

          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', color: '#44b700' }}>
            <WarningAmberIcon sx={{ marginRight: '8px', color: '#ed6c02' }} />
            <Typography fontSize={13} theme={theme} color="white">
              <b>Enregistrez !</b>
            </Typography>
          </Box>

            
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', color: '#44b700' }}>
              <CheckCircleOutlineIcon sx={{ marginRight: '8px', color: '#44b700' }} />
              <Typography fontSize={13} theme={theme} color="white">
                <b>À jour</b>
              </Typography>
            </Box>
          )} */}
          
        </Box>
        
        
      </Paper>
    </Box>
  );
}

export default SimplePaper;
