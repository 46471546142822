import React, { useState } from 'react';
import { Alert, Typography } from '@mui/material';


function SuccessToast() {

  return (
    <div>
        <Alert severity="success" style={{ borderRadius: 0 }}>
            Rapport enregistré avec succès.
        </Alert>
    </div>
  );
}

export default SuccessToast;
