import { tr } from "date-fns/locale";
import dayjs from "dayjs";
import { act } from "react-dom/test-utils";
import roundToQuarter from "./scale";



const handleTotal = (start, end, mealTime) => {
    const newStart = dayjs(start);
    const newEnd = dayjs(end);
    const tempNewTotal = newEnd.diff(newStart, 'hour', true);
    if (isNaN(tempNewTotal)) {
      return 0;
    } else {
      if (tempNewTotal < 0) {
        const newTotal = 24 + tempNewTotal - Number(mealTime);
        return roundToQuarter(newTotal);
      } else {
        const newTotal = tempNewTotal - Number(mealTime);
        return roundToQuarter(newTotal);
      }
    }
  }

export const timeAllocationErrorCheck = (activity) => {
    const totalWork = handleTotal(activity.start, activity.end, activity.meal_time);
    const arrayOfDictionaries = activity.time_allocations;
    const sum = arrayOfDictionaries.reduce((accumulator, currentValue) => {
        // Access the 'value' property from each dictionary
        const value = currentValue.duration;
      
        // Add the current value to the accumulator
        return accumulator + value;
      }, 0);
    
    if (totalWork !== sum) {
        return true;
    }

    return false;
};


export const workHoursErrorCheck = (activity) => {
    if (activity.start instanceof dayjs) {
        if (!(activity.start.isValid())) {
            return true
        }
    } else if (activity.end instanceof dayjs) {
        if (!(activity.end.isValid())) {
            return true
        }
    } else {
        return ((activity.start === null && activity.end !== null) || (activity.start !== null && activity.end === null));
    }
    
}
