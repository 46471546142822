import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormControl, InputLabel, Select, MenuItem, responsiveFontSizes, createTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import roundToQuarter from '../../../utils/scale';
import { DesktopTimePicker } from '@mui/x-date-pickers';


let theme = createTheme();
theme = responsiveFontSizes(theme)

function ActivityMobile(props) {

  const { report, idx, onDataChange } = props;

  const activity = report.activities[idx];
  const status = report.status;

  const initAccessValue = (status) => {
    if (status === 'En cours') {
      return true;
    } else if (status === 'En traitement') {
      return false;
    } else if (status === 'Complété') {
      return false;
    } else if (status === 'Archivé') {
      return false;
    } else {
      return false;
    }
  }

  const handleTotal = (start, end, mealTime) => {
    const newStart = dayjs(start);
    const newEnd = dayjs(end);
    const tempNewTotal = newEnd.diff(newStart, 'hour', true);
    if (isNaN(tempNewTotal)) {
      return 0;
    } else {
      if (tempNewTotal < 0) {
        const newTotal = 24 + tempNewTotal - Number(mealTime);
        return roundToQuarter(newTotal);
      } else {
        const newTotal = tempNewTotal - Number(mealTime);
        return roundToQuarter(newTotal);
      }
    }
  }

  const [mealTime, setMealTime] = useState(activity.meal_time ? activity.meal_time : 0);
  const [value, setValue] = useState([activity.start ? dayjs(activity.start) : null, activity.end ? dayjs(activity.end) : null]);
  const [startDate, setStartDate] = useState(dayjs(activity.date));
  const [total, setTotal] = useState(handleTotal(activity.start, activity.end, activity.meal_time));
  const [access, setAccess] = React.useState(initAccessValue(status));

  useEffect(() => {
    if (status === 'En cours') {
      setAccess(true);
    } else if (status === 'En traitement') {
      setAccess(false);
    } else if (status === 'Complété') {
      setAccess(false);
    } else if (status === 'Archivé') {
      setAccess(false);
    } else {
      setAccess(false);
    }

  }, [status])


  useEffect(() => {
    if (value[0] && value[1]) {
      if (value[0].isValid() && value[1].isValid()) {
        const tempNewTotal = value[1].diff(value[0], 'hour', true);
        if (tempNewTotal < 0) {
          const newTotal = 24 + tempNewTotal - Number(mealTime);
          setTotal(roundToQuarter(newTotal));
        } else {
          const newTotal = tempNewTotal - Number(mealTime);
          setTotal(roundToQuarter(newTotal));
        }
      } else {
        setTotal(0);
      }
    }

  }, [value, mealTime])

  const handleMealTimeChange = (e) => {
    const newData = report;
    newData.activities[idx].meal_time = e;
    onDataChange(newData);
    setMealTime(e);
  }

  const handleWorkTimeChange = (e) => {
    const newData = report;
    newData.activities[idx].start = e[0];
    newData.activities[idx].end = e[1];
    onDataChange(newData);
    setValue(e);
  }


  const handleStartTimeChange = (e) => {

    const newData = { ...report };
    let adjustedEndTime = value[1];

    if (adjustedEndTime && e.isValid()) {
      adjustedEndTime = adjustedEndTime
      .set('year', e.year())
      .set('month', e.month())
      .set('date', e.date());

      newData.activities[idx].end = adjustedEndTime;
    }
    
    newData.activities[idx].start = e;
    onDataChange(newData);
    setValue([e, adjustedEndTime]);

  }

  const handleEndTimeChange = (e) => {

    const newData = { ...report };
    let adjustedStartTime = value[0];

    if (adjustedStartTime && e.isValid()) {
      console.log(adjustedStartTime);
      adjustedStartTime = adjustedStartTime
      .set('year', e.year())
      .set('month', e.month())
      .set('date', e.date());

      newData.activities[idx].start = adjustedStartTime;
    }
    
    newData.activities[idx].end = e;
    onDataChange(newData);
    setValue([adjustedStartTime, e]);
  };

  return (
    <div>
        <Typography theme={theme} sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <b>Heures de travail</b>
          <IconButton sx={{ marginLeft: '5px' }}>
            <Tooltip title='Inscrire vos heures de début et de fin, ainsi que le temps de repas utilisé dans la journée' enterTouchDelay={0}>
              <InfoOutlinedIcon fontSize='small'/>
            </Tooltip>
          </IconButton>
        </Typography>
      <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DesktopTimePicker
              label="Début"
              value={value[0]} 
              onChange={(e) => handleStartTimeChange(e)}
              format="HH:mm"
              ampm={false}
              readOnly={!access}
              sx={{ width: '100%' }}
            />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DesktopTimePicker
                label="Fin"
                value={value[1]} 
                onChange={(e) => handleEndTimeChange(e)}
                format="HH:mm"
                ampm={false}
                readOnly={!access}
                sx={{ width: '100%' }}
              />
        </Grid>
        </LocalizationProvider>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControl fullWidth style={{ marginTop: '0px' }}>
            <InputLabel id="demo-simple-select-label">
              <Typography theme={theme}>Temps de repas</Typography>
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => handleMealTimeChange(e.target.value)}
              value={mealTime}
              label='Temps de repass'
              readOnly={!access}
            >
              <MenuItem value='0'><Typography theme={theme}>Aucun</Typography></MenuItem>
              <MenuItem value='0.25'><Typography theme={theme}>15 minutes</Typography></MenuItem>
              <MenuItem value='0.5'><Typography theme={theme}>30 minutes</Typography></MenuItem>
              <MenuItem value='0.75'><Typography theme={theme}>45 minutes</Typography></MenuItem>
              <MenuItem value='1'><Typography theme={theme}>1 heure</Typography></MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
          <TextField
            fullWidth
            id="filled-read-only-input"
            label="Heures totales"
            value={total.toFixed(2)}
            InputProps={{
              readOnly: true,
              style: {
                fontWeight: 'bold'
              }
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
      {/* <AggregationInitialState></AggregationInitialState> */}
    </div>
  );
}

export default ActivityMobile;
