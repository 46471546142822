import * as React from 'react';
import { Box } from '@mui/material';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


const rows = [
  {
    id: '1',
    employee: '395',
    equipment: null,
    equipment_hours: null,
    contract: '718005',
    phase: '002008',
    spending_code: '01',
    gl: '53100000',
    spending_equipment: null,
    cause: null,
    regular_hours: 41,
    half_hours: null,
    double_hours: null,
    entry_date: '20231209',
    job_code: null,
    ccq_region: null,
    annexe: 'D4',
    simple_rate: null,
    half_rate: null,
    double_rate: null
  },
  {
    id: '2',
    employee: '55',
    equipment: null,
    equipment_hours: null,
    contract: '718005',
    phase: '002008',
    spending_code: '01',
    gl: '53100000',
    spending_equipment: null,
    cause: null,
    regular_hours: 40,
    half_hours: null,
    double_hours: null,
    entry_date: '20231209',
    job_code: null,
    ccq_region: null,
    annexe: 'D4',
    simple_rate: null,
    half_rate: null,
    double_rate: null
  },
  {
    id: '3',
    employee: '54',
    equipment: null,
    equipment_hours: null,
    contract: '718005',
    phase: '002008',
    spending_code: '01',
    gl: '53100000',
    spending_equipment: null,
    cause: null,
    regular_hours: 41,
    half_hours: null,
    double_hours: null,
    entry_date: '20231209',
    job_code: null,
    ccq_region: null,
    annexe: 'D4',
    simple_rate: null,
    half_rate: null,
    double_rate: null
  }
];

const columns = [
  {
    field: 'employee',
    hidden: true,
    renderHeader: () => (
         <strong>
           {'Employé'}
         </strong>
       ),
    width: 150,
    type: 'string'
  },
  {
    field: 'equipment',
    renderHeader: () => (
        <strong>
          {'Équipement'}
        </strong>
      ),
    type: 'string',
    width: 150,
  },
  {
    field: 'equipment_hours',
    renderHeader: () => (
        <strong>
          {'Heures machine'}
        </strong>
      ),
    type: 'number',
    width: 150,
  },
  {
    field: 'contract',
    renderHeader: () => (
        <strong>
          {'Contrat'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'phase',
    renderHeader: () => (
        <strong>
          {'Phase'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'spending_code',
    renderHeader: () => (
        <strong>
          {'Code de dépense'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'gl',
    renderHeader: () => (
        <strong>
          {'Grand-Livre'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'spending_equipment',
    renderHeader: () => (
        <strong>
          {'Équip dépense'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'cause',
    renderHeader: () => (
        <strong>
          {'Cause'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'regular_hours',
    renderHeader: () => (
        <strong>
          {'Temps simple'}
        </strong>
      ),
    width: 150,
    type: 'number'
  },
  {
    field: 'half_hours',
    renderHeader: () => (
        <strong>
          {'Temps et demi'}
        </strong>
      ),
    width: 150,
    type: 'number'
  },
  {
    field: 'double_hours',
    renderHeader: () => (
        <strong>
          {'Temps double'}
        </strong>
      ),
    width: 150,
    type: 'number'
  },
  {
    field: 'entry_date',
    renderHeader: () => (
        <strong>
          {'Date entrée'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'job_code',
    renderHeader: () => (
        <strong>
          {'Code métier'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'ccq_region',
    renderHeader: () => (
        <strong>
          {'Région CCQ'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'annexe',
    renderHeader: () => (
        <strong>
          {'Annexe'}
        </strong>
      ),
    width: 150,
    type: 'string'
  },
  {
    field: 'simple_rate',
    renderHeader: () => (
        <strong>
          {'Taux simple'}
        </strong>
      ),
    width: 150,
    type: 'number'
  },
  {
    field: 'half_rate',
    renderHeader: () => (
        <strong>
          {'Taux demi'}
        </strong>
      ),
    width: 150,
    type: 'number'
  },
  {
    field: 'double_rate',
    renderHeader: () => (
        <strong>
          {'Taux double'}
        </strong>
      ),
    width: 150,
    type: 'number'
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function ImportTable() {
  return (
    <Box sx={{ padding: '18px' }}>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white', padding: '12px', borderRadius: '6px' }}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker calendars={1} />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Box>
        <Box sx={{ backgroundColor: 'white', padding: '12px', borderRadius: '6px', marginTop: '12px' }}>
        <DataGridPremium
            rows={rows}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}
            columnVisibilityModel={{
                cause: false,
                job_code: false,
                half_hours: false,
                half_rate: false
            }}
        />
        </Box>
    </Box>
  );
}