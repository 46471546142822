import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useMediaQuery } from "react-responsive";


let theme = createTheme();
theme = responsiveFontSizes(theme);


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: -1,
        left: -1,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));



export default function UserList(props) {

  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery({maxWidth: 600});

  const handleClick = (user_id) => {
    navigate(`/users/${user_id}`);
  };

  const users = props.users;

  return (
    
    <List
      sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {users.map((user) => (
        <Box>
          <ListItemButton onClick={() => handleClick(user.user_id)}>
            <ListItemIcon>
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          variant="dot"
                        >
                        <Avatar>
                            <PersonIcon></PersonIcon>
                          </Avatar>
                        </StyledBadge>
              </ListItemIcon>
          <ListItemText primary={user.name} secondary={user.position}/>
          {isSmallScreen ? null : (
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {user.created_at}
                </Typography>
              </Box>
            )}
        </ListItemButton>
        <Divider />
      </Box>
      ))}
    </List>

  );
}