import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import jwtDecode from 'jwt-decode';
import SimplePaper from './details';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';



let theme = createTheme();
theme = responsiveFontSizes(theme);

const StyledBadge = styled(Badge)(({ theme, badge }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: badge || '#44b700',
      color: badge || '#44b700',
      boxShadow: `0 0 0 0px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: -1,
        left: -1,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));


function InfoMobile(props) {
    const [token, setToken] = React.useState(localStorage.getItem('token'));
    const [access, setAccess] = React.useState(false);
    const [role, setRole] = React.useState(jwtDecode(token).sub.role);

    const [containerWidth, setContainerWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


    React.useEffect(() => {
      const role = jwtDecode(token).sub.role;
      if (role === 'admin') {
        setAccess(true);
        setRole(role);

      }

    }, [token])

    const report = props.report;
    const onDataChange = props.onDataChange;
    const onDataSave = props.onDataSave;
    const onStatusChange = props.onStatusChange;
    const isUpdate = props.isUpdate;

    const [status, setStatus] = React.useState(report.status);
    const [approved, setApproved] = React.useState(report.approved);


    const handleChange = (event) => {
      const newData = report;
      newData.status = event.target.value;
      onDataChange(newData);
      setStatus(event.target.value);
      const payload = {
        status: event.target.value
      };
      onStatusChange(payload);
    };

    const isSmallScreen = useMediaQuery('(max-width:400px)');

    const badgeColor = status === 'En cours' ? '#44b700' : status === 'En traitement' ? '#FFEA00' : status === 'Complété' ? '#0096FF' : status === 'Archivé' ? '#EE4B2B'  : '#44b700';
    

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#343434', color: 'white' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 3, paddingTop: 1, backgroundColor: '#343434', color: 'white' }}>
            {/* <Box>
                <Typography variant='h5' theme={theme}>
                    Rapport de temps
                </Typography>
            </Box> */}
            <Box sx={{ marginTop: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0.5 }}>
                  <Typography fontSize={isSmallScreen ? 12.5 : 14} theme={theme}>
                    {report.user_name}
                  </Typography>
                  <Chip label={report.user_number} size="small" variant='outlined' sx={{ color: 'white', marginLeft: 1, background: '#595959' }} />
                </Box>
                <Box sx={{ marginBottom: 0.5 }}>
                    <Typography fontSize={isSmallScreen ? 12.5 : 14} theme={theme}>
                        {report.start} - {report.end}
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: 0.5 }}>
                    <Typography fontSize={isSmallScreen ? 12.5 : 14} theme={theme}>
                        {report.sector}
                    </Typography>
                </Box>
                <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" marginLeft={-1}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={status}
                          label="Statut"
                          sx={{ color: 'white', fontSize: '13px' }}
                          onChange={handleChange}
                          readOnly={!access}
                          renderValue={(status) => (
                            <React.Fragment>
                              <Box sx={{ paddingLeft: '10px' }}>
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                  variant="dot"
                                  badge={badgeColor}
                                  badgeContent={null} // Set to null to hide the dot badge, adjust other badge props as needed
                                />
                              
                              <span style={{ marginLeft: '15px' }}>{status}</span> {/* Adjust the left spacing as needed */}
                              </Box>
                            </React.Fragment>
                          )}
                        >

                            <MenuItem value={'En cours'}>En cours</MenuItem>
                            <MenuItem value={'En traitement'}>En traitement</MenuItem>
                            <MenuItem value={'Complété'}>Complété</MenuItem>
                            <MenuItem value={'Archivé'}>Archivé</MenuItem>

                        </Select>
                      </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box sx={{ paddingLeft: 3, paddingBottom: 1 }}>
                <SimplePaper report={report} isUpdate={isUpdate} onDataChange={onDataChange} onStatusChange={onStatusChange} role={role}/>
              </Box>
            </Grid>
          </Grid>
          {/* {(role === 'admin' || role === 'foreman') && ( // Ensure to use strict equality (===)
            <Box sx={{ alignSelf: 'flex-start' }}>
              <FormGroup>
                <FormControlLabel control={<Checkbox color="success" checked={approved} onChange={handleCheckboxChange} />} label="Approuvé" />
              </FormGroup>
            </Box>
          )} */}
        </Box>
    </Box>
  );
}

export default InfoMobile;
