import * as React from 'react';
import { createTheme, responsiveFontSizes } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import MoreIcon from '@mui/icons-material/MoreVert';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import whitelogo from '../../assets/cppi-logo-white.png';
import blacklogo from '../../assets/cppi-logo-black.png';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import jwtDecode from 'jwt-decode';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import fetchDataAndGeneratePdf from '../../utils/jspdf';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';




let theme = createTheme();
theme = responsiveFontSizes(theme)

export default function PrimaryAppBar(props) {

  const page = props.page;
  const report_id = props.report_id;

  const tabValueTemp = page === 'Rapports de temps' ? '1' : page === 'Utilisateurs' ? '2' : '1';

  const [token, setToken] = React.useState(localStorage.getItem('token'));
  const [username, setUsername] = React.useState(jwtDecode(token).sub.user_name);
  const [role, setRole] = React.useState(jwtDecode(token).sub.role);
  const [tabValue, setTabValue] = React.useState(tabValueTemp);

  const navigate = useNavigate();

  React.useEffect(() => {
    setUsername(jwtDecode(token).sub.user_name);
    setRole(jwtDecode(token).sub.role)

  }, [token]);

  
  const handleTabChange = (event, newValue) => {
    
    if (newValue === '1') {
      navigate('/timesheets');
    } else if (newValue === '2') {
      navigate('/users');
    }
    setTabValue(newValue);
  };


  const [anchorEl, setAnchorEl] = React.useState(null);  
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {

    localStorage.removeItem('token');
    localStorage.removeItem('refresh');

    navigate('/');

  }

  const handleBack = () => {

    navigate('/timesheets')

  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      {page === 'Rapport de temps' && (
        <MenuItem onClick={() => fetchDataAndGeneratePdf(report_id)}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <PictureAsPdfIcon  />
        </IconButton>
        <p>Enregistrer en format PDF</p>
      </MenuItem>
      )}
      
    </Menu>
  );


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  let appBarLogoMarginLeft = 0;

  if (xl) {
    if (page === 'Rapport de temps') {
      appBarLogoMarginLeft = 0;
    } else {
      appBarLogoMarginLeft = 130;
    }
  } else if (lg) {
    appBarLogoMarginLeft = 0;
  }

  let redirectList = ['Rapports de temps']

  if (role === 'admin') {

    redirectList = ['Rapports de temps', 'Utilisateurs']

  }

  
  const redirect = ['/timesheets', '/users']

  const list = (anchor) => (
    <Box sx={{ backgroundColor: '#343434' }}>
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, marginTop: 6, backgroundColor: '#343434' }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Divider sx={{ backgroundColor: '#696969'  }}/>
        <List>
          {redirectList.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton component={Link} to={redirect[index]}>
                <ListItemIcon sx={{ color: '#E0E0E0' }}>
                  {index === 0 ? <AccessTimeIcon /> : index === 1 ? <PeopleOutlineIcon /> : <MailIcon/>}
                </ListItemIcon>
                <ListItemText
                primary={
                  <Typography variant="body1" color="white" sx={{ fontFamily: 'Arial, sans-serif' }}>
                    {text}
                  </Typography>
                }
              />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ backgroundColor: '#696969'  }} />
      </Box>
    </Box>
  );

  const drawer = (  
    <div>
      <ThemeProvider theme={theme}>
      <React.Fragment key='left'>
        <Drawer
          anchor='left'
          open={state['left']}
          onClose={toggleDrawer('left', false)}
        >
          <Box sx={{ backgroundColor: '#343434' }}>
            <Box sx={{ marginTop: '15px', marginLeft: '35px', marginRight: '20px', alignContent: 'center' }}>
              <img src={whitelogo} alt="logo" width="175" />
            </Box>
          </Box>
          {list('left')}
          <div style={{ flex: 1 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', height: '100%', backgroundColor: '#343434' }}>
            
            <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
              <Box sx={{ padding: 1 }}>
                  <Avatar sx={{ bgcolor: deepOrange[500], borderRadius: '4px' }} variant="square">
                    <Typography theme={theme} sx={{ fontFamily: 'Arial, sans-serif' }}>
                      {username[0]}
                    </Typography>
                </Avatar>
              </Box>
              <Box>
                <Typography theme={theme} sx={{ color: '#E0E0E0', fontFamily: 'Arial, sans-serif' }}>
                  {username}
                </Typography>
              </Box>
            </Box>
            </Box>
            <Box sx={{ padding: 2 }}>
              <Box>
                <Button sx={{ color: '#E0E0E0' }} onClick={handleLogout} startIcon={<LogoutIcon />}>
                  <Typography theme={theme} sx={{ color: '#E0E0E0', fontFamily: 'Arial, sans-serif' }}>
                    Se déconnecter
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
      </ThemeProvider>
    </div>
  )

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ backgroundColor: '#343434' }}>
        <Toolbar>

          {page === 'Rapport de temps' ? (
            <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 2 }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          ) : (
            
            <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer('left', true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          )}
          
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <div style={{ marginTop: '6px', marginLeft: appBarLogoMarginLeft, marginRight: '20px', alignContent: 'center' }}>
              <img src={whitelogo} alt="logo" width="175" />
            </div>
            
          </Typography>

          
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Box sx={{ color: 'white', display: { xs: 'block', md: 'none' } }}>
                <Typography theme={theme} variant="h7" sx={{ fontFamily: 'Arial, sans-serif' }}>
                  {page}
                </Typography>
                </Box>
              </Box>
              <Box sx={{ marginLeft: 2, display: { xs: 'none', md: 'block' } }}>
              
                {role === 'admin' && (page === 'Rapports de temps' || page === 'Utilisateurs') ? (
                  <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="inherit" aria-label="basic tabs example">
                    <Tab label="Rapports de temps" value="1" sx={{ color: 'white' }} />
                    <Tab label="Utilisateurs" value="2" sx={{ color: 'white' }} />
                  </Tabs>  
                ) : page === 'Rapports de temps' || page === 'Utilisateurs' ? (
                  <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="inherit" aria-label="basic tabs example">
                    <Tab label="Rapports de temps" value="1" sx={{ color: 'white' }} />
                  </Tabs>
                ) : (<></>)}  
              </Box>
            </Box>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Rechercher…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {page === 'Rapport de temps' && (
              <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={() => fetchDataAndGeneratePdf(report_id)}
            >
              <PictureAsPdfIcon />
            </IconButton>
            )}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {drawer}
      {renderMobileMenu}
      </ThemeProvider>
    </Box>
  );
}
