import {SignIn} from '../components/login/sign_in'
import { useNavigate } from 'react-router-dom';
import { isConnected } from '../utils/jwt';
import React, { useEffect } from 'react';


export function SignInPage() {
  const navigate = useNavigate();
  const is_connected = isConnected();

  useEffect(() => {
    if (is_connected) {
      console.log('User is connected');
      navigate('/timesheets');
    }
  }, [is_connected, navigate]);

  if (!is_connected) {
    return (
      <div>
        <SignIn></SignIn>
      </div>
    );
  }

  // You can return null here since the navigation will occur in the effect
  return null;
}
