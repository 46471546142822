import * as React from 'react';
import './App.css';
import {SignInPage} from './views/sign_in'
import { UserListPage } from './views/users';
import { TimesheetsPage } from './views/timesheets';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { TimesheetPage } from './views/timesheet';
import { LicenseInfo } from '@mui/x-license-pro';
import { ImportPage } from './views/import';
import jwtDecode from 'jwt-decode';


LicenseInfo.setLicenseKey('6d930661a1434b6eed53bac589264255Tz03OTMyOSxFPTE3MzIzODk4NjkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')


const theme = createTheme({
  typography: {
    fontFamily: '"Segoe UI Emoji"', // Change 'Your-Font-Family' to your desired font family
  },
});

function isAuthenticated() {
  const token = localStorage.getItem('token'); // Retrieve the token from storage
  // Implement token validation logic here
  return !!token; // Return true if the token is valid, false otherwise
}

function isAdmin() {
  const token = localStorage.getItem('token');
  const role = jwtDecode(token).sub.role;

  if (role === 'admin') {
    return true;
  } else {
    return false
  }
}

const MyErrorFallback = ({ error, resetErrorBoundary }) => (
  <div>
    <h2>Something went wrong</h2>
    <p>{error.message}</p>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);

function App() {
  // Function to protect routes by checking authentication
  const ProtectedRoute = ({ element, path }) => {
    if (!isAuthenticated()) {
      return <Navigate to="/" />;
    } else if (path === '/users' && !isAdmin()) {
      return <Navigate to="/" />;
    }
    return element;
  };

  return (
      <Router>
        <Routes>
          <Route path="/" element={<ThemeProvider theme={theme}><SignInPage /></ThemeProvider>} />
          <Route path="/users" element={<ProtectedRoute path="/users" element={<ThemeProvider theme={theme}><UserListPage /></ThemeProvider>} />} />
          <Route path="/timesheets" element={<ProtectedRoute path="/timesheets" element={<ThemeProvider theme={theme}><TimesheetsPage /></ThemeProvider>} />} />
          <Route path="/timesheets/:id" element={<ProtectedRoute path="/timesheets/:id" element={<ThemeProvider theme={theme}><TimesheetPage /></ThemeProvider>} />} />
          <Route path="/import" element={<ProtectedRoute path="/import" element={<ThemeProvider theme={theme}><ImportPage /></ThemeProvider>} />} />
          <Route path="*" element={<Navigate to="/" />} /> {/* Handle unknown routes */}
        </Routes>
      </Router>
  );
}


export default App;
