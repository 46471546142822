import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ImageMobile from './image';
import Stack from '@mui/material/Stack';


export default function FileBox(props) {
    const theme = useTheme();
    const filename = props.filename;
    const size = props.size;
    const idx = props.idx;
    const handleDelete = props.handleDelete;
    var file = props.file;


    if (file instanceof File) {
      file = URL.createObjectURL(file)
    }

  return (
    <Stack direction="row" spacing={2}>
    <Card
        sx={{
        display: 'flex',
        boxShadow: 'none', // Remove the shadow
        }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
        <ImageMobile file={file} idx={idx} handleDelete={handleDelete} ></ImageMobile>
      </Box>
    </Card>
    </Stack>
  );
}