import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


function NoteMobile(props) {
  const { report, idx, onDataChange } = props;

  const activity = report.activities[idx];

  const [note, setNote] = useState(activity.comments ? activity.comments : "");

  const initAccessValue = (status) => {
    if (status === 'En cours') {
      return true;
    } else if (status === 'En traitement') {
      return false;
    } else if (status === 'Complété') {
      return false;
    } else if (status === 'Archivé') {
      return false;
    } else {
      return false;
    }
  }

  const handleNoteChange = (e) => {
    const newData = report;
    newData.activities[idx].comments = e;
    onDataChange(newData);
    setNote(e);
  }

    const status = report.status;
    const [access, setAccess] = React.useState(initAccessValue(status));

    React.useEffect(() => {
      if (status === 'En cours') {
        setAccess(true);
      } else if (status === 'En traitement') {
        setAccess(false);
      } else if (status === 'Complété') {
        setAccess(false);
      } else if (status === 'Archivé') {
        setAccess(false);
      } else {
        setAccess(false);
      }

    }, [status])


  return (
    <div>
        <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <b>Commentaires</b>
          <IconButton sx={{ marginLeft: '5px' }}>
            <Tooltip title='Ajouter des commentaires sur votre journée' enterTouchDelay={0}>
              <InfoOutlinedIcon fontSize='small'/>
            </Tooltip>
          </IconButton>
        </Typography>
        <Box
        sx={{
            maxWidth: '100%',
            color: 'white'
        }}
        >
            <TextField fullWidth label="Commentaires" id="fullWidth" InputProps={{ readOnly: !access }} inputProps={{ maxLength: 100 }} value={note} onChange={(e) => handleNoteChange(e.target.value)}/>
        </Box>
    </div>
  );
}

export default NoteMobile;
