export default function roundToQuarter(floatValue) {
    const decimalPart = floatValue % 1; // Extract the decimal part
    let roundedDecimal;
  
    if (decimalPart < 0.125) {
      roundedDecimal = 0;
    } else if (decimalPart < 0.375) {
      roundedDecimal = 0.25;
    } else if (decimalPart < 0.625) {
      roundedDecimal = 0.5;
    } else if (decimalPart < 0.875) {
      roundedDecimal = 0.75;
    } else {
      roundedDecimal = 1;
    }
  
    const roundedValue = Math.floor(floatValue) + roundedDecimal;
    return roundedValue;
  }
  