import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { createTheme, responsiveFontSizes, Button, IconButton } from '@mui/material';
import { ThemeContext, ThemeProvider } from '@emotion/react';
import UserList from './list';
import UserFilteringGrid from './new_list_user';


let theme = createTheme();
theme = responsiveFontSizes(theme);





function MobileUserList(props) {

  const onChange = props.onUserActiveChange;
  const onUserUpdate = props.onUserUpdate;
  const onUserCreation = props.onUserCreation;
  const onUserDeletion = props.onUserDeletion;
  const users = Array.isArray(props.users) ? props.users : [];
  

  return (
      <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh', paddingTop: '0.1px' }}>
        <ThemeProvider theme={theme}>
          <Box sx={{ backgroundColor: 'white' }}>
              <UserFilteringGrid users={users} onActiveChange={onChange} onUserUpdate={onUserUpdate} onUserCreation={onUserCreation} onUserDeletion={onUserDeletion}></UserFilteringGrid>
          </Box>
        </ThemeProvider>
      </div>
  );
}

export default MobileUserList;